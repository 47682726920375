<template>
  <div>
    <Table :status="3" />
  </div>
</template>

<script>
import Table from './Table.vue'
export default {
  components: {
    Table
  }
}
</script>

<style>

</style>